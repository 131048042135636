import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { scrollToAnchor } from 'src/utils/helpers';
import { Tab, Tabs } from 'material-ui/Tabs';
import SwipeableViews from 'react-swipeable-views';
import autoBind from 'react-autobind';
import {
    asyncCheckDuplicate,
    formatMinutes,
    Location,
    pluralizeDays,
    renderCheckbox,
    renderGalleryImages,
    renderImageSelector,
    renderInput,
    renderMultiSelectCheckboxes,
    renderRichTextEditor,
    renderSelectField,
    renderSlider,
    validateLocation,
} from 'src/components/misc/redux-form-helpers';
import { ImageCropperTypes } from 'src/components/images/image-cropper';
import ContactDataInputFields from 'src/components/misc/ContactDataInputFields';
import { isBlank, isUrl } from 'src/components/misc/validations';
import { Currency, isPercentTwoDecimalPlaces, TOMCAT_URL } from 'src/common/index';
import MenuItem from 'material-ui/MenuItem';
import { ReleaseMode } from 'src/utils/constants';
import { FINDER_LANGUAGES } from 'src/i18n';
import { setSliderIndex } from 'src/actions/form';

class CompanyEditForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            freeCancellation: props.initialValues.freeCancellation,
        };
    }

    componentWillMount() {
        this.props.setSliderIndex(0);
    }

    render() {
        const {
            handleSubmit,
            disabled,
            isAdmin,
            isCreate,
            isTripCompany,
            tripCategories,
            tripAttributes,
            initialValues,
            company,
            selectedSliderIndex,
            t,
        } = this.props;

        const readonly = this.props.readonly ? true : false;

        const availableRegistrationTOSVersions = !company
            ? initialValues.company.availableRegistrationTOSVersions
            : company.availableRegistrationTOSVersions;
        const isRatioCompany = initialValues.ratioClient != null;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <input type="hidden" name="id" />

                {this.renderTabs(selectedSliderIndex, t, isAdmin, isRatioCompany, isTripCompany)}
                <SwipeableViews index={selectedSliderIndex} onChangeIndex={value => this.props.setSliderIndex(value)}>
                    <div className="view">
                        <div className="row">
                            <div className="col-md-6">
                                <legend className="legend">{t('companies.company_data')}</legend>
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <Field
                                            name="logo"
                                            component={renderImageSelector}
                                            t={t}
                                            type={
                                                isTripCompany
                                                    ? ImageCropperTypes.TRIP_COMPANY
                                                    : ImageCropperTypes.COMPANY
                                            }
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div id="topSection" className="col-md-12">
                                        <Field
                                            name="companyName"
                                            label={`${t('common_phrases.name')} *`}
                                            component={renderInput}
                                            disabled={disabled}
                                            props={{
                                                disabled: readonly,
                                            }}
                                        />
                                    </div>
                                    {isTripCompany && (
                                        <div className="col-md-12">
                                            <Field
                                                name="subtitle"
                                                label={`${t('companies.subtitle')} *`}
                                                component={renderInput}
                                                disabled={disabled}
                                                props={{
                                                    disabled: readonly,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div className="col-md-12">
                                        <Field
                                            name="registeredCompanyName"
                                            label={`${t('companies.registered_name')} *`}
                                            component={renderInput}
                                            disabled={disabled}
                                            props={{
                                                disabled: readonly,
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <Field
                                            name="language"
                                            label={`${t('language')} *`}
                                            component={renderSelectField}
                                            props={{
                                                disabled: readonly,
                                            }}>
                                            {FINDER_LANGUAGES.map(language => (
                                                <MenuItem
                                                    key={language.code}
                                                    value={language.code}
                                                    primaryText={language.label}
                                                />
                                            ))}
                                        </Field>
                                    </div>
                                    {!disabled && (
                                        <div className="col-md-12">
                                            <Field
                                                name="location"
                                                label={`${t('common_phrases.location')} *`}
                                                component={Location}
                                                props={{
                                                    disabled: readonly,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div className="col-md-12">
                                        <Field
                                            name="uidNumber"
                                            label={t('companies.uid_number')}
                                            component={renderInput}
                                            disabled={disabled}
                                            props={{
                                                disabled: readonly,
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <Field
                                            name="registrationNumber"
                                            label={t('companies.registration_number')}
                                            component={renderInput}
                                            disabled={disabled}
                                            props={{
                                                disabled: readonly,
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <Field
                                            name="socialCapital"
                                            label={t('companies.social_capital')}
                                            component={renderInput}
                                            disabled={disabled}
                                            props={{
                                                disabled: readonly,
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <Field
                                            name="invoiceEmail"
                                            label={t('companies.invoice_email')}
                                            component={renderInput}
                                            disabled={disabled}
                                            props={{
                                                disabled: readonly,
                                            }}
                                        />
                                    </div>
                                    {!isTripCompany && (
                                        <div className="col-md-12">
                                            <Field
                                                name="registrationTOS"
                                                label={`${t('common_phrases.company_tos_version')} *`}
                                                component={renderSelectField}
                                                props={{
                                                    disabled: readonly,
                                                }}>
                                                {availableRegistrationTOSVersions.map(version => (
                                                    <MenuItem key={version} value={version} primaryText={version} />
                                                ))}
                                            </Field>
                                        </div>
                                    )}
                                    {isAdmin && (
                                        <div className="col-md-12">
                                            <Field
                                                name="accountingNumberInvoice"
                                                label={`${t('common_phrases.company_bmd_account_number')}`}
                                                component={renderInput}></Field>
                                            <Field
                                                name="accountingCompanyName"
                                                label={`${t('common_phrases.company_bmd_customer_name')}`}
                                                component={renderInput}></Field>
                                        </div>
                                    )}
                                    <div className="col-md-12">
                                        <Field
                                            name="emergencyContactNumber"
                                            label={`${t('common_phrases.emergency_contact_number')}`}
                                            component={renderInput}
                                            placeholder={t(
                                                'common_phrases.emergency_contact_number_placeholder',
                                            )}></Field>
                                    </div>
                                    <div className="col-md-12 voffset40">
                                        <Field
                                            name="freeCancellation"
                                            label={t('companies.free_cancelling')}
                                            component={renderCheckbox}
                                            disabled={disabled}
                                            props={{
                                                disabled: readonly,
                                            }}
                                            customOnCheckCallback={value => this.setState({ freeCancellation: value })}
                                        />
                                    </div>
                                    {this.state.freeCancellation && (
                                        <div className="col-md-12">
                                            <Field
                                                name="freeCancellationDays"
                                                label="companies.free_cancellation_days_before_departure"
                                                component={renderSlider}
                                                labelValueFormat={pluralizeDays}
                                                min={0}
                                                max={90}
                                                step={1}
                                                t={t}
                                                props={{
                                                    disabled: readonly,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div className="col-md-12">{t('companies.cancellation_hint')}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ContactDataInputFields t={t} readOnly={disabled} readOnlyTripCompany={readonly} />
                            </div>
                        </div>
                        {isTripCompany && (
                            <div className="row">
                                <div className="col-md-12 voffset">
                                    <Field
                                        name="recommendedDurationOfStayInMinutes"
                                        label="companies.recommended_duration_of_stay_in_minutes"
                                        component={renderSlider}
                                        labelValueFormat={formatMinutes}
                                        t={t}
                                        min={0}
                                        max={720}
                                        step={15}
                                        props={{
                                            disabled: readonly,
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-md-12">
                                <br />
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>{t('common_phrases.description')}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="description"
                                            placeholder={t('common_phrases.description')}
                                            component={renderRichTextEditor}
                                            t={t}
                                            props={{
                                                disabled: readonly,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isTripCompany && (
                            <>
                                <div className="row">
                                    <div className="col-md-12">
                                        <br />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>{t('companies.short_description')}</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Field
                                                    name="shortDescription"
                                                    placeholder={t('companies.short_description_placeholder')}
                                                    multiline={true}
                                                    rowsMax={7}
                                                    component={renderInput}
                                                    props={{
                                                        disabled: readonly,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <br />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>{t('companies.booking_conditions')}</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Field
                                                    name="bookingConditions"
                                                    placeholder={t('companies.booking_conditions')}
                                                    component={renderRichTextEditor}
                                                    t={t}
                                                    props={{
                                                        disabled: readonly,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <br />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>{t('companies.product_information')}</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Field
                                                    name="productInformation"
                                                    placeholder={t('companies.product_information')}
                                                    component={renderRichTextEditor}
                                                    t={t}
                                                    props={{
                                                        disabled: readonly,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="row">
                            <div className="col-md-12">
                                <br />
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>{t('companies.agb')}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="agb"
                                            placeholder={t('companies.agb')}
                                            component={renderRichTextEditor}
                                            t={t}
                                            props={{
                                                disabled: readonly,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <br />
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>{t('companies.payment_conditions')}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="paymentConditions"
                                            placeholder={t('companies.payment_conditions')}
                                            component={renderRichTextEditor}
                                            t={t}
                                            props={{
                                                disabled: readonly,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <br />
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>{t('companies.reversal_conditions')}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="reversalConditions"
                                            placeholder={t('companies.reversal_conditions')}
                                            component={renderRichTextEditor}
                                            t={t}
                                            props={{
                                                disabled: readonly,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <br />
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>{t('companies.confirmation_conclusion')}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="confirmationConclusion"
                                            placeholder={t('companies.confirmation_conclusion')}
                                            component={renderRichTextEditor}
                                            t={t}
                                            props={{
                                                disabled: readonly,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="view">
                        {(isAdmin || isRatioCompany) && !isTripCompany && (
                            <div className="row">
                                <div className="col-md-12">
                                    <legend className="legend">{t('companies.ratio_settings')}</legend>
                                </div>
                                <div className="col-md-12 voffset">
                                    {/*TODO: BUF-1135: translate*/}
                                    <Field
                                        name="ratioCreateTransfer"
                                        label="Ratio Transfer erstellen?"
                                        component={renderCheckbox}
                                        disabled={disabled}
                                        props={{
                                            disabled: readonly,
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    {/*TODO: BUF-1135: translate*/}
                                    <Field
                                        name="ratioEinsatzart"
                                        label="Ratio Einsatzart"
                                        component={renderInput}
                                        disabled={disabled}
                                        props={{
                                            disabled: readonly,
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    {/*TODO: BUF-1135: translate*/}
                                    <Field
                                        name="ratioDatevKonto"
                                        label="Ratio Datev-Konto"
                                        component={renderInput}
                                        disabled={disabled}
                                        props={{
                                            disabled: readonly,
                                        }}
                                    />
                                </div>
                                <div className="col-md-12 voffset">
                                    {/*TODO: BUF-1135: translate*/}
                                    <Field
                                        name="ratioCreateZahlung"
                                        label="Ratio Zahlung erstellen?"
                                        component={renderCheckbox}
                                        disabled={disabled}
                                        props={{
                                            disabled: readonly,
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    {/*TODO: BUF-1135: translate*/}
                                    <Field
                                        name="ratioZahlungSoll"
                                        label="Ratio Zahlung Soll"
                                        component={renderInput}
                                        disabled={disabled}
                                        props={{
                                            disabled: readonly,
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    {/*TODO: BUF-1135: translate*/}
                                    <Field
                                        name="ratioZahlungErfasstDurch"
                                        label="Ratio Zahlung Erfasst Durch"
                                        component={renderInput}
                                        disabled={disabled}
                                        props={{
                                            disabled: readonly,
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {isAdmin && (
                            <div className="row voffset40">
                                <div className="col-md-12">
                                    <legend className="legend">{t('companies.admin_settings')}</legend>
                                </div>
                                <div className="col-md-12">
                                    <Field
                                        name="currency"
                                        label={`${t('companies.currency')} *`}
                                        component={renderSelectField}
                                        disabled={!(isCreate || isAdmin) || disabled}
                                        props={{
                                            disabled: readonly,
                                        }}>
                                        {Object.keys(Currency).map(currency => (
                                            <MenuItem key={currency} value={currency} primaryText={currency} />
                                        ))}
                                    </Field>
                                </div>
                                {!isTripCompany && (
                                    <>
                                        <div className="col-md-6">
                                            <Field
                                                name="bookingFee"
                                                label={t('companies.booking_fee')}
                                                component={renderInput}
                                                type="number"
                                                disabled={disabled}
                                                props={{
                                                    disabled: readonly,
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Field
                                                name="ftpCompanyId"
                                                label={t('companies.ftp_company_id')}
                                                hintText={t('companies.ftp_company_id_hint')}
                                                component={renderInput}
                                                disabled={disabled}
                                                props={{
                                                    disabled: readonly,
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Field
                                                name="ftpPassword"
                                                label={t('companies.ftp_password')}
                                                hintText={t('companies.ftp_password_hint')}
                                                component={renderInput}
                                                disabled={disabled}
                                                props={{
                                                    disabled: readonly,
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Field
                                                name="importReleaseMode"
                                                label={t('companies.import_release_mode')}
                                                component={renderSelectField}
                                                props={{
                                                    disabled: readonly,
                                                }}>
                                                <MenuItem
                                                    key={0}
                                                    value={ReleaseMode.BLOCKED}
                                                    primaryText={t('bus.blocked')}
                                                />
                                                <MenuItem
                                                    key={1}
                                                    value={ReleaseMode.RELEASED}
                                                    primaryText={t('bus.free')}
                                                />
                                            </Field>
                                        </div>
                                        <div className="col-md-6 voffset">
                                            <Field
                                                name="ratioClient"
                                                label={t('companies.ratio_client')}
                                                component={renderInput}
                                                disabled={disabled}
                                                props={{
                                                    disabled: readonly,
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6 voffset">
                                            <Field
                                                name="ratioApiKey"
                                                label={t('companies.ratio_api_key')}
                                                component={renderInput}
                                                disabled={disabled}
                                                props={{
                                                    disabled: readonly,
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-12 voffset">
                                            <Field
                                                name="turistaCompanyId"
                                                label={t('companies.turista_company_id')}
                                                component={renderInput}
                                                disabled={disabled}
                                                props={{
                                                    disabled: readonly,
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Field
                                                name="turistaUsername"
                                                label={t('companies.turista_username')}
                                                component={renderInput}
                                                disabled={disabled}
                                                props={{
                                                    disabled: readonly,
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Field
                                                name="turistaPassword"
                                                label={t('companies.turista_password')}
                                                component={renderInput}
                                                disabled={disabled}
                                                props={{
                                                    disabled: readonly,
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-12 voffset40">
                                            <Field
                                                name="integrationOnlyBuses"
                                                label={t('companies.integration_only_buses')}
                                                component={renderCheckbox}
                                                disabled={disabled}
                                                props={{
                                                    disabled: readonly,
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="view">
                        <div className="row">
                            {!isTripCompany && company && !company.onlinePaymentModuleEnabled && (
                                <div className="col-md-12">
                                    {/*TODO: BUF-1135: translate*/}
                                    <div className="alert alert-warning">Das zugehörige Modul ist deaktiviert!</div>
                                </div>
                            )}
                            <div className="col-md-12">
                                {/*TODO: BUF-1135: translate*/}
                                <Field
                                    name="onlinePaymentSettings.merchantId"
                                    label="Konto-ID"
                                    component={renderInput}
                                    props={{
                                        disabled: readonly,
                                    }}
                                />
                            </div>
                            <div className="col-md-12">
                                {/*TODO: BUF-1135: translate*/}
                                <Field
                                    name="onlinePaymentSettings.apiKey"
                                    label="API Schlüssel"
                                    component={renderInput}
                                    props={{
                                        disabled: readonly,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="view">
                        <Field
                            name="attributes"
                            component={renderMultiSelectCheckboxes}
                            items={tripAttributes}
                            iconProperty="icon"
                            props={{
                                disabled: readonly,
                            }}
                        />
                    </div>
                    <div className="view">
                        <Field name="categories" component={renderMultiSelectCheckboxes} items={tripCategories} />
                    </div>
                    <div className="view">
                        <Field
                            name="documents"
                            component={renderGalleryImages}
                            t={t}
                            type={ImageCropperTypes.TRIP_COMPANY}
                            props={{
                                disabled: readonly,
                            }}
                        />
                    </div>
                </SwipeableViews>
            </form>
        );
    }

    renderTabs = (selectedSliderIndex, t, isAdmin, isRatioCompany, isTripCompany) => {
        const interfacesIndex = isAdmin || isRatioCompany ? 1 : 0;
        if (isTripCompany) {
            return (
                <Tabs onChange={value => this.props.setSliderIndex(value)} value={selectedSliderIndex}>
                    <Tab label={t('common_phrases.common')} value={0} />
                    {(isAdmin || isRatioCompany) && <Tab label={t('companies.interfaces')} value={1} />}
                    <Tab label={t('payment.title')} value={interfacesIndex + 1} />
                    <Tab label={t('companies.properties')} value={interfacesIndex + 2} />
                    <Tab label={t('common_phrases.categories')} value={interfacesIndex + 3} />
                    <Tab label={t('companies.more_pictures')} value={interfacesIndex + 4} />
                </Tabs>
            );
        } else {
            return (
                <Tabs onChange={value => this.props.setSliderIndex(value)} value={selectedSliderIndex}>
                    <Tab label={t('common_phrases.common')} value={0} />
                    {(isAdmin || isRatioCompany) && <Tab label={t('companies.interfaces')} value={1} />}
                    <Tab label={t('payment.title')} value={interfacesIndex + 1} />
                </Tabs>
            );
        }
    };
}

const validate = (values, props) => {
    const { isTripCompany, t } = props;
    const errors = {
        contactData: {},
    };

    if (isBlank(values.companyName)) errors.companyName = t('error_missing.fill_in_name');

    if (isTripCompany && isBlank(values.subtitle)) errors.subtitle = t('companies.fill_in_subtitle');

    if (isTripCompany && values.shortDescription && values.shortDescription.length > 255)
        errors.shortDescription = t('companies.error_length');

    if (isBlank(values.registeredCompanyName)) errors.registeredCompanyName = t('error_missing.fill_in_company_name');

    if (!values.contactData.gender) errors.contactData.gender = t('error_missing.fill_in_gender');

    if (isBlank(values.contactData.firstName)) errors.contactData.firstName = t('error_missing.fill_in_first_name');

    if (isBlank(values.contactData.lastName)) errors.contactData.lastName = t('error_missing.fill_in_last_name');

    if (isBlank(values.contactData.street)) errors.contactData.street = t('error_missing.fill_in_street');

    if (isBlank(values.contactData.postCode)) errors.contactData.postCode = t('error_missing.fill_in_postal_code');

    if (isBlank(values.contactData.city)) errors.contactData.city = t('error_missing.fill_in_city');

    if (isBlank(values.contactData.country)) errors.contactData.country = t('error_missing.fill_in_country');

    if (isBlank(values.contactData.email)) errors.contactData.email = t('error_missing.fill_in_email');

    if (!isUrl(values.contactData.homepage)) errors.contactData = { homepage: t('error_missing.fill_in_homepage') };

    if (!isBlank(values.ratioClient) && isBlank(values.ratioApiKey)) errors.ratioApiKey = t('error_missing.fill_in');

    if (!isBlank(values.ratioApiKey) && isBlank(values.ratioClient)) errors.ratioClient = t('error_missing.fill_in');

    if (isBlank(values.currency)) errors.currency = t('error_missing.fill_in');

    if (!isBlank(values.emergencyContactNumber)) {
        const validatePhone = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
        if (!validatePhone.test(values.emergencyContactNumber))
            errors.emergencyContactNumber = t('error_missing.fill_in_correct_emergency_contact_number');
    }

    if (isTripCompany && isBlank(values.bookingFee)) errors.bookingFee = t('error_missing.fill_in');
    else if (!isPercentTwoDecimalPlaces(values.bookingFee))
        errors.bookingFee = t('error_missing.fill_in_two_decimal_places_percent');

    if (!values.location) errors.location = t('error_missing.fill_in_location');
    else if (validateLocation(values.location)) errors.location = t('bus.select_address');

    return errors;
};

const asyncValidate = (values, dispatch, props) => {
    if (!values.companyName) return Promise.resolve();

    return asyncCheckDuplicate(
        props.t,
        `${TOMCAT_URL}api/companies/search/findByCompanyName?companyName=${encodeURIComponent(values.companyName)}`,
        values.id,
        'companyName',
    );
};

const onSubmitFail = (errors, dispatch) => {
    if (errors.currency || errors.bookingFee || errors.ratioClient || errors.ratioApiKey) dispatch(setSliderIndex(1));
    else {
        scrollToAnchor('topSection');
        dispatch(setSliderIndex(0));
    }
};

export default reduxForm({
    form: 'companyEditForm',
    onSubmitFail,
    validate,
    asyncValidate,
    asyncBlurFields: ['companyName'],
})(CompanyEditForm);
